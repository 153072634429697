
import { defineComponent } from '@nuxtjs/composition-api'
import { FulfillingBouncingCircleSpinner } from 'epic-spinners'

export default defineComponent({
  name: 'PSpinner',
  components: {
    FulfillingBouncingCircleSpinner,
  },
  props: {
    size: {
      type: Number,
      default: 80,
    },
    color: {
      type: String,
      default: 'rgb(var(--p-color-primary-100))',
    },
  },
})
