export type ConstraintViolation = {
  code: string
  message: string
  // eslint-disable-next-line camelcase
  property_path: string
}

export class ConstraintsViolatedError extends Error {
  public readonly violations: Array<ConstraintViolation>

  public constructor(violations: Array<ConstraintViolation>) {
    super()

    this.violations = violations
  }

  public getViolations(): Array<ConstraintViolation> {
    return this.violations
  }
}
