import { queryOptions, useQuery } from '@tanstack/vue-query'
import { computed, Ref, unref } from '@nuxtjs/composition-api'
import { MaybeRef } from '@vueuse/shared'
import { useUserApiService } from '~/src/composables/dependency'

export const userQueries = {
  byId(userId: MaybeRef<string>) {
    const userApiService = useUserApiService()

    return queryOptions({
      queryKey: ['user', userId] as const,
      queryFn({ queryKey: [, userId] }) {
        return userApiService.get(userId)
      },
      staleTime: 60000,
    })
  },
  userInfo(userId: MaybeRef<string | undefined>) {
    const userApiService = useUserApiService()
    const enabled = computed(() => unref(userId) !== undefined)

    return queryOptions({
      queryKey: ['user', 'info', userId],
      queryFn() {
        const userIdValue = unref(userId)

        if (userIdValue === undefined) {
          throw new Error('userId may not be undefined')
        }

        return userApiService.info(userIdValue)
      },
      staleTime: 5000,
      enabled,
    })
  },
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUserInfoQuery(
  userId: Ref<string | undefined> | string | undefined,
  {
    refetchOnWindowFocus = true,
  }: {
    refetchOnWindowFocus?: boolean
  } = {}
) {
  return useQuery(
    queryOptions({ ...userQueries.userInfo(userId), refetchOnWindowFocus })
  )
}
